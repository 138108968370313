<template>
  <v-select
    prefix="Ano"
    prepend-icon="mdi-calendar"
    hide-details
    dense
    :items="years"
    :value="value || defaultValue"
    @change="onChange($event)"
  ></v-select>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'year-select',
  created: function () {
    const validValues = this.years.map(({ value }) => value);
    if (!validValues.includes(this.value)) {
      this.onChange(this.defaultValue);
    }
  },
  computed: {
    ...mapGetters(['anoBase']),
    defaultValue: function () {
      const anoBase = parseInt(this.anoBase);

      if (anoBase < this.min) {
        return this.min;
      }

      if (anoBase > this.max) {
        return this.max;
      }

      return this.anoBase;
    },
    years: function () {
      const min = this.min >= 2020 ? this.min : 2020;
      const initialYear = moment([min]);
      const finalYear = this.max >= 2020 ? moment([this.max]) : moment();
      const length = finalYear.diff(initialYear, "years") + 1;
      return Array(length)
        .fill(0)
        .map((_, index) => {
          const date = moment(finalYear).subtract(index, "years").format("YYYY");
          return {
            text: date,
            value: date,
          };
        });
    },
  },
  methods: {
    onChange: function (event) {
      this.$emit('change', event);
      this.setFilters({ anoBase: event });
      this.$store.commit('setAnoBase', event);
    }
  },
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    max: {
      type: Number,
      default: moment().get('year'),
    },
    min: {
      type: Number,
      default: 2020,
    },
    value: {
      type: [String, Boolean],
    },
  },
}
</script>
